var exports = {};

/**
 * This file automatically generated from `pre-publish.js`.
 * Do not manually edit.
 */
exports = {
  "area": true,
  "base": true,
  "br": true,
  "col": true,
  "embed": true,
  "hr": true,
  "img": true,
  "input": true,
  "link": true,
  "meta": true,
  "param": true,
  "source": true,
  "track": true,
  "wbr": true
};
export default exports;
export const area = exports.area,
      base = exports.base,
      br = exports.br,
      col = exports.col,
      embed = exports.embed,
      hr = exports.hr,
      img = exports.img,
      input = exports.input,
      link = exports.link,
      meta = exports.meta,
      param = exports.param,
      source = exports.source,
      track = exports.track,
      wbr = exports.wbr;